.observations-container {
  border-left: 5px solid #a07702;
  border-right: 5px solid #a07702;
  background-color: white;
}
.main-container {
  background-color: rgb(230, 230, 230);
  border-top: 2px dashed rgb(200, 200, 200);
  border-bottom: 2px dashed rgb(200, 200, 200);
}

.table-column-pressable {
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.top-border {
  border-top: 2px dashed #a07702;
}
