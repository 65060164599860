@import "./Colors.scss";

.bg-main {
  background-color: map-get($theme-colours, primary);
}

.bg-light {
  background-color: map-get($theme-colours, light);
}

.bg-white {
  background-color: map-get($theme-colours, white);
}

.clip-odd {
  -webkit-clip-path: polygon(
    50% 0%,
    100% 0,
    100% 95%,
    50% 100%,
    0% 95%,
    0 0
  );
  clip-path: polygon(
    50% 0%,
    100% 0,
    100% 95%,
    50% 100%,
    0% 95%,
    0 0
  );
}

.nav-item-hover:hover .report-icon {
  color: white;
}

.report-icon {
  color: #dc3545;
  transition: color 0.3s;
}

/**
  * Adds negative margin to the right equal to element width
 */
.negate-size {
  width: 100%;
  margin-right: -100%;
}

.dark-select {
  background-color: map-get($theme-colours, dark);
  color: map-get($theme-colours, white);
  .custom-icon {
    fill: map-get($theme-colours, white);
  }
}

.dark-negate:hover {
  background-color: map-get($theme-colours, dark);
  color: map-get($theme-colours, white);
  cursor: pointer;
  .custom-icon {
    fill: map-get($theme-colours, white);
  }
}

.dash-bar-element {
  padding-top: 100px;
}

@media (min-width: 992px) {
  .gap-on-lg {
    gap: 4rem;
  }
  .clip-odd {
    -webkit-clip-path: polygon(
      50% 0%,
      100% 0,
      100% 75%,
      50% 100%,
      0% 75%,
      0 0
    );
    clip-path: polygon(
      50% 0%,
      100% 0,
      100% 75%,
      50% 100%,
      0% 75%,
      0 0
    );
  }
}
