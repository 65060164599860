.main-container {
  background-color: rgb(230, 230, 230);
  border-top: 2px dashed rgb(200, 200, 200);
  border-bottom: 2px dashed rgb(200, 200, 200);
}

.main-container-no-border {
  background-color: rgb(230, 230, 230);
}

.check-box-float-container {
  background-color: #f5f5f5;
  border-bottom: 2px dashed rgb(200, 200, 200);
}

.observation-container {
  border-left: 5px solid #a07702;
  border-right: 5px solid #a07702;
  background-color: white;
}

.highlight {
  border-left: 5px solid #ffbe00;
  border-right: 5px solid #ffbe00;
}

.icon-hover {
  color: #a07702;
  &:hover {
    color: #ffbe00;
    cursor: pointer;
  }
}

.regular-checkbox {
  -webkit-appearance: none;
  background-color: #fafafa;
  border: 1px solid #cacece;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  height: 26px;
  width: 26px;
  border-radius: 3px;
  display: inline-block;
  position: relative;
}

.regular-checkbox:active,
.regular-checkbox:checked:active {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px 1px 3px rgba(0, 0, 0, 0.1);
}

.regular-checkbox:checked:after {
  content: "\2713";
  font-size: 1em;
  position: absolute;
  top: 0em;
  left: 0.3em;
  right: 0.3em;
  color: #99a1a7;
}

.regular-checkbox:checked {
  background-color: #ffbe00;
  border: 1px solid #adb8c0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05),
    inset 15px 10px -12px rgba(255, 255, 255, 0.1);
  color: #99a1a7;
}

.table-striped tr:nth-child(even) {
  background-color: rgb(240, 240, 240);
}

.table-column-pressable {
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.icon-hover-svg {
  max-height: 20px;
  fill: #a07702;
  &:hover {
    fill: #ffbe00;
    cursor: pointer;
  }
}

.observation-keyword-bubble {
  background-color: #a07702;
  color: white;
  border-radius: 50px;
  padding: 3px;
  margin: 3px;
  width: 25%;
  &:hover {
    background-color: #ffbe00;
    cursor: pointer;
  }
}

.submit-button {
  &:focus {
    border: 2px dashed #8f6901;
  }
}

.button-selected {
  background-color: #ffbe00;
  border: 1px dashed #ffbe00;
}

.button-unselected {
  background-color: #f8f9fa;
  border: 1px dashed #ffbe00;
  &:hover {
    background-color: #ffbe00;
    border: 1px dashed #f8f9fa;
  }
}

.button-csv-download {
  background-color: #f8f9fa;
  border: 1px dashed #f8f9fa;
  &:hover {
    background-color: #99a1a7;
    border: 1px dashed #99a1a7;
  }
}