.primary-bg {
  background-color: #a07702 !important;
}

.header-container {
  border-left: 5px solid #a07702;
  border-right: 5px solid #a07702;
  background-color: white;
}

.header-container-favourite {
  border-left: 5px solid #ffbe00;
  border-right: 5px solid #ffbe00;
  background-color: white;
}

.header-container-own {
  border-left: 5px solid #14a002;
  border-right: 5px solid #14a002;
  background-color: white;
}

.main-container {
  background-color: rgb(230, 230, 230);
  border-top: 2px dashed rgb(200, 200, 200);
  border-bottom: 2px dashed rgb(200, 200, 200);
}

.table-column-pressable {
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.text {
  color: white;
}

.person-count {
  display: block ruby;
}

.pressable {
  &:hover {
    cursor: pointer;
    text-decoration: underline;
    color: #ffbe00;
  }
}

.table-striped > tbody > tr:nth-child(odd) > td,
.table-striped > tbody > tr:nth-child(odd) > th {
  background-color: rgb(
    230,
    230,
    230
  ); // Choose your own color here
}

.language-button {
  color: white;
}
