@import "../Styles/Colors.scss";

.fselect {
  // A reset of styles, including removing the default dropdown arrow
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  // Additional resets for further consistency
  background-color: transparent;
  border: none;
  padding: 0 1em 0 0;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  border: 1px solid rgb(178, 178, 178);
  -webkit-appearance: none;
  background-color: white;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 50%;
}

* {
  --switch-height: 34px;
  --switch-padding: 8px;
  --switch-width: calc(
    (var(--switch-height) * 2) - var(--switch-padding)
  );
  --slider-height: calc(
    var(--switch-height) - var(--switch-padding)
  );
  --slider-on: calc(
    var(--switch-height) - var(--switch-padding)
  );
}

.switch {
  position: relative;
  display: inline-block;
  width: var(--switch-width);
  height: var(--switch-height);
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  content: "";
  position: absolute;
  height: var(--slider-height);
  width: var(--slider-height);
  left: calc(var(--switch-padding) / 2);
  bottom: calc(var(--switch-padding) / 2);
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #ffbe00;
}

input:focus + .slider {
  box-shadow: 0 0 1px #ffbe00;
}

input:checked + .slider:before {
  transform: translateX(var(--slider-on));
}

.slider.round {
  border-radius: var(--slider-height);
}

.slider.round:before {
  border-radius: 50%;
}

.custom-radio {
  display: flex;
}

.custom-radio-option {
  position: relative;
}

.custom-radio-option label {
  display: block;
}

.custom-radio-option:before {
  content: "";
  width: 30px;
  height: 30px;
  border: 2px solid lightgray;
  position: absolute;
  left: -30px;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.2s ease;
}

.custom-radio-option:hover:before {
  border-color: #ffbe00;
}

.custom-radio-option.selected:before {
  content: "";
  width: 30px;
  height: 30px;
  background-color: #ffbe00;
  position: absolute;
  left: -30px;
  top: 50%;
  transform: translateY(-50%);
}
